var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Informasi Pembelian")]), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Supplier")]), _c('v-select', {
    attrs: {
      "options": _vm.suppliers,
      "label": "text"
    },
    model: {
      value: _vm.supplier,
      callback: function ($$v) {
        _vm.supplier = $$v;
      },
      expression: "supplier"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "3"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Tanggal Pembelian")]), _c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "3"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Tanggal Perkiraan Datang (opsional)")]), _c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.jatuh_tempo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "jatuh_tempo", $$v);
      },
      expression: "form.jatuh_tempo"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Keterangan (opsional)")]), _c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('Rincian Barang')) + " "), _vm.selectedBarangs.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.selectedBarangs.length) + ")")]) : _vm._e()]), _c('b-button', {
    staticClass: "mb-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v("Pilih Barang")]), _vm.selectedBarangs.length > 0 ? _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.selectedBarangs
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(no_container)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: MRT123213"
          },
          model: {
            value: item.no_container,
            callback: function ($$v) {
              _vm.$set(item, "no_container", $$v);
            },
            expression: "item.no_container"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.nama))])];
      }
    }, {
      key: "cell(no_seal)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: GR123"
          },
          model: {
            value: item.no_seal,
            callback: function ($$v) {
              _vm.$set(item, "no_seal", $$v);
            },
            expression: "item.no_seal"
          }
        })];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.qty) + " /" + _vm._s(item.satuan ? item.satuan.satuan : "") + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref10) {
        var item = _ref10.item,
          index = _ref10.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }], null, false, 3482581260)
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_c('strong', [_vm._v("Harap")]), _vm._v(" pilih barang rincian")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-3 px-2"
  }, [_c('h4', [_vm._v("Total Pembelian:")]), _c('h2', {
    staticClass: "ml-2"
  }, [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalPembelian)))])])], 1), _c('barang-modal', {
    attrs: {
      "barangs": _vm.barangs
    },
    on: {
      "submit": _vm.onChooseBarang
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.isValidForm,
      "variant": !_vm.isValidForm ? 'secondary' : 'primary'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }